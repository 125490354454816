import MessageIcon from "./icons/message.svg";
import { ReactComponent as ProfileIcon } from "./icons/person.svg";
import { ReactComponent as Messagenoback } from "./icons/message.svg";
import { ReactComponent as Notifnoback } from "./icons/notifications.svg";
import NotificationIcon from "./icons/notifications.svg";
import { ReactComponent as BossupIcon } from "./icons/bossupicon.svg";
import Badge from "./icons/badge.svg";
import Logo from "./icons/logo.png";
import { ReactComponent as Home } from "./icons/home.svg";
import { ReactComponent as User } from "./icons/person.svg";
import { ReactComponent as MarketPlace } from "./icons/marketplace.svg";
import { ReactComponent as Private } from "./icons/private.svg";
import { ReactComponent as Visible } from "./icons/eye.svg";
import { ReactComponent as Google } from "./icons/g.svg";
import { ReactComponent as Send } from "./icons/send.svg";
import { ReactComponent as File } from "./icons/file.svg";
import { ReactComponent as Upload } from "./icons/upload.svg";
import Coin from "./icons/coin.svg";
import Viewsicon from "./icons/views.svg";
import Comment from "./icons/comment.svg";
import Share from "./icons/share.svg";
import Like from "./icons/like.svg";
import { ReactComponent as Activemessage } from "./icons/activemessage.svg";
import LikeFilled from "./icons/likefilled.svg";
import Block from "./icons/block.svg";
import Gallery from "./icons/gallery.svg";
import One from "./icons/one.png";
import Lefttab from "./icons/lefttab.png";
import Planpicture from "./icons/planpicture.png";
import Two from "./icons/two.png";
import Three from "./icons/three.png";
import Four from "./icons/four.png";
import Five from "./icons/five.png";
import Rocket from "./icons/rocket.svg";
import BoostBanner from "./icons/boost_banner.png";
import Instagram from "./icons/instagram_o.svg";
import Twitter from "./icons/twitter_o.svg";
import Trophy from "./icons/trophy.svg";
import Product from "./icons/product.svg";
import Invite from "./icons/invitepicture.png";
import { ReactComponent as ActiveNotification } from "./icons/active_notification.svg";
import { ReactComponent as Backbutton } from "./icons/backbutton.svg";
import { ReactComponent as Appstorelogo } from "./icons/app-store.svg";
import { ReactComponent as Playstorelogo } from "./icons/google-play.svg";
import ad1 from "./icons/ad1.png";
import ad2 from "./icons/ad2.png";
import ad3 from "./icons/ad3.png";
import ad4 from "./icons/ad4.png";
import ad5 from "./icons/ad5.png";
import ad6 from "./icons/ad6.png";
import Liveeventback from "./icons/liveeventback.jpeg";
import Authback from "./icons/authback.png";
import NoProfile from "./icons/noprofile.png";
import Mobiletop from "./icons/mobiletopnotsignedinuser.png";
import Notsignedinpopupback from "./icons/nosignedinpopupback.png";
import { ReactComponent as Checkmark } from "./icons/premiumbadge.svg";
import { ReactComponent as NextIconPrimary } from "./icons/nexticon_primary.svg";
import { ReactComponent as Moreconnections } from "./icons/moreconnections.svg";
import { ReactComponent as Rankingicon } from "./icons/rankingicon.svg";
import { ReactComponent as Nexticon } from "./icons/nexticon.svg";
import { ReactComponent as Analyse } from "./icons/analyze.svg";
import { ReactComponent as Connectrelevant } from "./icons/connectrelevant.svg";
import { ReactComponent as Ranking } from "./icons/ranking.svg";
import { ReactComponent as Explore } from "./icons/explore.svg";
import { ReactComponent as Reporticon } from "./icons/report.svg";
import { ReactComponent as Entries } from "./icons/entries.svg";
import { ReactComponent as Topicsicon } from "./icons/topics.svg";
import { ReactComponent as Inviteicon } from "./icons/invite.svg";
import { ReactComponent as Membersicon } from "./icons/members.svg";
import { ReactComponent as Linkicon } from "./icons/link.svg";
import dashboardicon from "./icons/dashboard.svg";
import timelapseicon from "./icons/timelapse.svg";
import BossUpAvatar from "./icons/bb_avatar.jpg";
import appmockup from "./icons/appmockup.png";
import marketplacelanding from "./icons/onlinestoreicon.png";
import monetisation from "./icons/monetisation.png";
import liveevents from "./icons/liveevent.png";
import businesstools from "./icons/businesstools.png";
import suppliers from "./icons/suppliers.png";
import analyser from "./icons/analyser.png";
import bbchallenge from "./icons/bbchallenge.png";
import homeimage from "./icons/homeimage.png";
import { ReactComponent as Quoteicon } from "./icons/quote.svg";
import { ReactComponent as Upicon } from "./icons/upicon.svg";
import hiwone from "./icons/hiwone.png";
import hiwtwo from "./icons/hiwtwo.png";
import hiwthree from "./icons/hiwthree.png";
import partner3 from "./icons/partner3.png";
import logo1 from "./icons/logo1.png";
import logo2 from "./icons/logo2.png";
import logo3 from "./icons/logo3.png";
import logo4 from "./icons/logo4.png";
import logo5 from "./icons/logo5.png";
import landing0 from "./icons/landing0.png";
import landing1 from "./icons/landing1.png";
import landing2 from "./icons/landing2.png";
import comingsoon from "./icons/comingsoon.png";
import bbboost from "./icons/bbboost.png";
import { ReactComponent as Bolticon } from "./icons/bolt.svg";
import { ReactComponent as shopchat } from "./icons/shopchat.svg";
import { ReactComponent as shopcall } from "./icons/shopcall.svg";
import { ReactComponent as shopshare } from "./icons/shopshare.svg";
import { ReactComponent as shopreview } from "./icons/shopreview.svg";
import { ReactComponent as location } from "./icons/location.svg";
import pwu1 from "./icons/pwu1.png";
import pwu2 from "./icons/pwu2.png";
import pwu3 from "./icons/pwu3.png";
import pwu4 from "./icons/pwu4.png";
import pwu5 from "./icons/pwu5.png";
import pwu6 from "./icons/pwu6.png";
import { ReactComponent as fbsl } from "./icons/fbsl.svg";
import { ReactComponent as xsl } from "./icons/xsl.svg";
import { ReactComponent as lsl } from "./icons/lsl.svg";
import { ReactComponent as isl } from "./icons/insta.svg";
import { ReactComponent as website } from "./icons/website.svg";
import { ReactComponent as email } from "./icons/email.svg";
import { ReactComponent as phone } from "./icons/phone.svg";
import orderonapp from "./icons/orderonapp.jpeg";
import { ReactComponent as order } from "./icons/order.svg";

const Assets = {
  MessageIcon,
  ProfileIcon,
  NotificationIcon,
  BossupIcon,
  Badge,
  Logo,
  Home,
  MarketPlace,
  Coin,
  Comment,
  Share,
  Like,
  LikeFilled,
  Block,
  Gallery,
  Rocket,
  BoostBanner,
  Instagram,
  Twitter,
  Trophy,
  Product,
  Invite,
  ActiveNotification,
  Notifnoback,
  Messagenoback,
  Activemessage,
  Private,
  Visible,
  Google,
  User,
  Send,
  File,
  Upload,
  Backbutton,
  Appstorelogo,
  Playstorelogo,
  Authback,
  Checkmark,
  Moreconnections,
  Rankingicon,
  Nexticon,
  Analyse,
  Connectrelevant,
  Ranking,
  Explore,
  One,
  Two,
  Three,
  Four,
  Five,
  Reporticon,
  Entries,
  Topicsicon,
  NoProfile,
  Inviteicon,
  Membersicon,
  Linkicon,
  NextIconPrimary,
  Planpicture,
  Lefttab,
  Mobiletop,
  Bolticon,
  Notsignedinpopupback,
  ad1,
  ad2,
  ad3,
  ad4,
  ad5,
  ad6,
  Viewsicon,
  Liveeventback,
  dashboardicon,
  timelapseicon,
  BossUpAvatar,
  appmockup,
  marketplacelanding,
  monetisation,
  liveevents,
  businesstools,
  suppliers,
  analyser,
  bbchallenge,
  homeimage,
  Quoteicon,
  Upicon,
  hiwone,
  hiwtwo,
  hiwthree,
  partner3,
  landing0,
  landing1,
  landing2,
  comingsoon,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  bbboost,
  pwu1,
  pwu2,
  pwu3,
  pwu4,
  pwu5,
  pwu6,
  shopchat,
  shopcall,
  shopshare,
  shopreview,
  location,
  xsl,
  fbsl,
  lsl,
  isl,
  website,
  email,
  phone,
  orderonapp,
  order,
};
export default Assets;
