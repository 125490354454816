const index = "/";
const home = "/home";
const marketPlace = "/market";
const communities = "/communities/*";
const opportunities = "/opportunities";
const learning = "/learning";
const forum = "/forum";
const myProfile = "/profile/myprofile";
const editProfile = "/profile/edit";
const PublicUserProfile = "/profile/publicProfile";
const createPost = "/create-post";
const promotePost = "/promote-post";
const settings = "/settings";
const invite = "/invite";
const chats = "/chats";
const ChatRoom = "/chatRoom";
const notifications = "/notifications";
const homeSearch = "/home-search";
const connections = "/connections";
const register = "/auth/register";
const login = "/auth/login";
const verifyOtp = "/auth/verify";
const forgotPassword = "/auth/forgot-password";
const CreateListing = "/create-listing";
const CreateBossup = "/create-bossup";
const refer = "/refer";
const subscriptionpage = "/subscriptionpage";
const communityrules = "/communityrules";
const invitetandcs = "/invitetandcs";
const analysepage = "/analysepage";
const analyseprofilepage = "/analyseprofilepage";
const connectrelevant = "/connectrelevant";
const rankingpage = "/rankingpage";
const explorebusinessbosses = "/explorebusinessbosses";
const sellerreview = "/sellerreview";
const expandedimages = "/expandedimages";
const bossuppartners = "/bossuppartners";
const connectrelevantpage = "/connectrelevantpage";
const subscriptionconfirmationpage = "/subscriptionconfirmationpage";
const renewsubscriptionconfirmationpage = "/renewsubscriptionconfirmationpage";
const boostpostconfirmationpage = "/boostpostconfirmationpage";
const boostpost = "/boostpost";
const communitiesSearch = "/communities-search";
const reviewpaymentpage = "/reviewpaymentpage";
const subscriptionfailedpage = "/subscriptionfailedpage";
const computerbossupsectionpage = "/computerbossupsectionpage";
const liveevent = "/liveevent";
const listingselectorpage = "/listingselectorpage";
const landingpage = "/landingpage";
const landingpageforpartners = "/landingpageforpartners";
const becomeapartner = "/becomeapartner";
const businesstools = "/businesstools";
const shop = "/biz-center";
const expandedshop = "/expandedshop";

const RoutesPath = {
  home,
  index,
  marketPlace,
  communities,
  opportunities,
  learning,
  forum,
  createPost,
  promotePost,
  myProfile,
  settings,
  invite,
  notifications,
  homeSearch,
  connections,
  login,
  register,
  verifyOtp,
  PublicUserProfile,
  editProfile,
  chats,
  ChatRoom,
  CreateListing,
  CreateBossup,
  forgotPassword,
  refer,
  subscriptionpage,
  communityrules,
  invitetandcs,
  analysepage,
  analyseprofilepage,
  connectrelevant,
  rankingpage,
  explorebusinessbosses,
  sellerreview,
  expandedimages,
  bossuppartners,
  connectrelevantpage,
  subscriptionconfirmationpage,
  renewsubscriptionconfirmationpage,
  boostpostconfirmationpage,
  boostpost,
  communitiesSearch,
  reviewpaymentpage,
  subscriptionfailedpage,
  computerbossupsectionpage,
  liveevent,
  listingselectorpage,
  landingpage,
  landingpageforpartners,
  businesstools,
  becomeapartner,
  shop,
  expandedshop,
};

export default RoutesPath;
